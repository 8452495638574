<template>
    	    <button
    class="flex cursor-pointer items-center justify-center w-10 h-10 bg-green-50 dark:bg-white border rounded-full focus:outline-none text-black-900"
    @click="openHelpPanel"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
    >
      <path
        d="M9.8636 0C4.44234 0 0 4.03721 0 8.96433C0 10.8736 0.669458 12.6472 1.80174 14.106L8.65981 23.8857C9.62023 25.0294 10.2581 24.8124 11.0565 23.8256L18.622 13.1187C18.7749 12.8667 18.8948 12.5989 18.9993 12.3249C19.4663 11.2859 19.7262 10.1511 19.7262 8.96433C19.7262 4.03721 15.2849 0 9.8636 0ZM9.87272 3.06545C10.4186 3.06545 10.8802 3.2605 11.2573 3.65058C11.6473 4.0276 11.8424 4.48895 11.8424 5.03511C11.8424 5.58104 11.6473 6.04904 11.2573 6.43888C10.8802 6.82897 10.4189 7.02352 9.87272 7.02352C9.3268 7.02352 8.85879 6.82872 8.46896 6.43913C8.07887 6.04904 7.88383 5.58104 7.88383 5.03511C7.88383 4.48919 8.07271 4.0276 8.44972 3.65058C8.83956 3.2605 9.31373 3.06545 9.87272 3.06545ZM10.8319 8.20216H11.2819C11.5418 8.20216 11.7529 8.36761 11.7529 8.57202V13.5006C11.7529 13.9398 11.8195 14.2352 11.8392 14.2759C11.891 14.3599 11.9447 14.4063 12.0172 14.4401C12.0389 14.449 12.2971 14.5052 12.7039 14.5052C12.9641 14.5052 13.1746 14.6709 13.1746 14.875V15.1645C13.1746 15.3687 12.9641 15.5344 12.7039 15.5344H7.16604C6.90615 15.5344 6.69508 15.3687 6.69508 15.1645V14.875C6.69508 14.6709 6.90615 14.5052 7.16604 14.5052C7.59262 14.5052 7.86854 14.4428 7.85473 14.449C7.90972 14.4243 7.9647 14.3836 8.0229 14.2778L8.02783 14.2687C8.05125 14.2293 8.12695 13.9358 8.12695 13.5006C8.12695 11.9309 8.18613 11.5872 8.0441 10.1738C8.00884 10.0559 7.95854 10.0162 7.9869 10.034L7.97235 10.0249C7.97185 10.0246 7.96002 10.0106 7.86114 10.0106C7.75413 10.0106 7.56993 10.0374 7.331 10.1077C7.08935 10.1787 6.82034 10.084 6.72688 9.89541L6.58338 9.60592C6.49017 9.41754 6.60705 9.20523 6.84549 9.12904L10.6549 8.22903C10.7111 8.21128 10.8928 8.20191 10.8322 8.20191L10.8319 8.20216Z"
        fill="black"
      />
    </svg>
  </button>
</template>
<script>
export default {
  methods: {
    openHelpPanel() {
      this.$emit('open-help-panel');
    },
  },
};
</script>
