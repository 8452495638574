<template>
    <button
      class="button-new flex items-center justify-center text-green-600 dark:text-white text-sm font-medium cursor-pointer"
      @click="handleClick"
    >
      <fluent-icon
        v-if="icon"
        :size="20"
        :icon="icon"
        class="mr-2"
      />
    </button>
  </template>

<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue';

export default {
  name: 'GreenButton',
  components: { EmojiOrIcon },
  props: {
    type: {
      type: String,
      default: 'submit',
    },
    variant: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>

<style scoped>

.button-new {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: none;
}

</style>
