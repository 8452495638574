<template>
  <div class="mb-4">
    <button
      @click="openNotificationPage"
      class="header-section flex items-center text-base font-medium cursor-pointer h-11 hover:text-green-600 dark:hover:text-green-600 w-full relative"
      :class="{
        'bg-woot-50 dark:bg-slate-800 text-green-500 hover:bg-woot-50 border-left':
          isNotificationPanelActive,
      }"
    >

      <fluent-icon icon="alert" size="20" class="icon icon--font ml-4 mr-4" />
      <span
        class="text-button flex items-start font-medium text-button w-40 ml-3"
        >{{ $t('Notificações') }}
      </span>
      <span
        class="text-black-900 bg-yellow-300 absolute button-counted p-1 text-xxs min-w-[1rem] rounded-full"
        v-if="unreadCount"
      >
        {{ unreadCount }}
      </span>
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      notificationMetadata: 'notifications/getMeta',
    }),
    unreadCount() {
      if (!this.notificationMetadata.unreadCount) {
        return '';
      }

      return this.notificationMetadata.unreadCount < 100
        ? `${this.notificationMetadata.unreadCount}`
        : '99+';
    },
    isNotificationPanelActive() {
      return this.$route.name === 'notifications_index';
    },
  },
  methods: {
    openNotificationPage() {
      if (this.$route.name !== 'notifications_index') {
        this.$router.push({
          name: 'notifications_index',
        });
      }
    },
  },
};
</script>

<style scoped>
.border-left {
  border-left: 2px solid #30a46c;
}

.button-counted{
  top: 6px;
  right: 4px;
  height: 30px;
}
</style>
