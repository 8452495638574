import { frontendURL } from '../../../../helper/URLHelper';

const crm = accountId => ({
  parentNav: 'crm',
  routes: ['settings_account_campaigns', 'one_off'],
  menuItems: [
    {
      icon: 'arrow-swap',
      label: 'CLIENTS',
      key: 'clients',
      toState: frontendURL(`accounts/${accountId}/crm/clients`),
      toStateName: 'clients',
    },
    {
      key: 'projects',
      icon: 'sound-source',
      label: 'PROJECTS',
      toState: frontendURL(`accounts/${accountId}/crm/projects`),
      toStateName: 'projects',
    },
    {
      key: 'tasks',
      icon: 'sound-source',
      label: 'TASKS',
      toState: frontendURL(`accounts/${accountId}/crm/tasks`),
      toStateName: 'tasks',
    },
    {
      key: 'leads',
      icon: 'sound-source',
      label: 'LEADS',
      toState: frontendURL(`accounts/${accountId}/crm/leads`),
      toStateName: 'leads',
    },
  ],
});

export default crm;
