<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('SCHEDULED_MESSAGE.ADD.TITLE')"
      :header-content="$t('SCHEDULED_MESSAGE.ADD.DESC')"
    />
    <form class="flex flex-col w-full" @submit.prevent="addScheduledMessage">
      <div class="w-full">
        <woot-input
          v-model="title"
          :label="$t('SCHEDULED_MESSAGE.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('SCHEDULED_MESSAGE.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('SCHEDULED_MESSAGE.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />
        <label :class="{ error: $v.message.$error }">
          {{ $t('SCHEDULED_MESSAGE.ADD.FORM.MESSAGE.LABEL') }}
          <textarea
            v-model="message"
            rows="5"
            type="text"
            :placeholder="$t('SCHEDULED_MESSAGE.ADD.FORM.MESSAGE.PLACEHOLDER')"
            @blur="$v.message.$touch"
          />
          <span v-if="$v.message.$error" class="message">
            {{ $t('SCHEDULED_MESSAGE.ADD.FORM.MESSAGE.ERROR') }}
          </span>
        </label>

        <label :class="{ error: $v.selectedInbox.$error }">
          {{ $t('SCHEDULED_MESSAGE.ADD.FORM.INBOX.LABEL') }}
          <select v-model="selectedInbox" @change="onChangeInbox($event)">
            <option v-for="item in inboxes" :key="item.name" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.selectedInbox.$error" class="message">
            {{ $t('SCHEDULED_MESSAGE.ADD.FORM.INBOX.ERROR') }}
          </span>
        </label>

        <label :class="{ error: $v.selectedContact.$error }">
          {{ $t('SCHEDULED_MESSAGE.ADD.FORM.CONTACT.LABEL') }}
          <select v-model="selectedContact" @change="onChangeContact($event)">
            <option v-for="item in contactList" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.selectedContact.$error" class="message">
            {{ $t('SCHEDULED_MESSAGE.ADD.FORM.CONTACT.ERROR') }}
          </span>
        </label>

        <label>
          {{ $t('SCHEDULED_MESSAGE.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-time-picker
            :value="scheduledAt"
            :confirm-text="$t('SCHEDULED_MESSAGE.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('SCHEDULED_MESSAGE.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
            @change="onChange"
          />
        </label>
      </div>

      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <woot-button :is-loading="uiFlags.isCreating">
          {{ $t('SCHEDULED_MESSAGE.ADD.CREATE_BUTTON_TEXT') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('SCHEDULED_MESSAGE.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { URLPattern } from 'urlpattern-polyfill';
import alertMixin from 'shared/mixins/alertMixin';
import { SCHEDULED_MESSAGES_EVENTS } from '../../../../helper/AnalyticsHelper/events';

export default {
  components: {
    WootDateTimePicker,
    WootMessageEditor,
  },
  mixins: [alertMixin],
  data() {
    return {
      title: '',
      message: '',
      selectedInbox: null,
      show: true,
      senderId: null,
      scheduledAt: null,
      selectedContact: null,
    };
  },

  validations() {
    return {
      title: {
        required,
      },
      message: {
        required,
      },
      selectedInbox: {
        required,
      },
      selectedContact: {
        required,
      },
      selectedAt: {
        required,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
      uiFlags: 'scheduledMessages/getUIFlags',
      contactList: 'contacts/getContacts'
    }),
    inboxes() {
      return [
        ...this.$store.getters['inboxes/getApiInboxes'],
      ];
    },
  },
  mounted() {
    this.$store.dispatch('contacts/get')
    this.$track(SCHEDULED_MESSAGES_EVENTS.OPEN_NEW_SCHEDULED_MESSAGE_MODAL);
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    onChangeContact(event) {
      this.selectedContact = event.target.value;
    },
    async onChangeInbox() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.selectedInbox,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.senderList = inboxMembers;
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('SCHEDULED_MESSAGE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    getScheduledMessageDetails() {
      let scheduledMessageDetails = null;
        scheduledMessageDetails = {
          title: this.title,
          message: this.message,
          inbox_id: this.selectedInbox,
          scheduled_at: this.scheduledAt,
          contact_id: this.selectedContact,
          sender_id: this.currentUserId,
          }
      return scheduledMessageDetails;
    },
    async addScheduledMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const scheduledMessageDetails = this.getScheduledMessageDetails();
        await this.$store.dispatch('scheduledMessages/create', scheduledMessageDetails);

        // tracking this here instead of the store to track the type of scheduledMessage
        this.$track(SCHEDULED_MESSAGES_EVENTS.CREATE_SCHEDULED_MESSAGE);

        this.showAlert(this.$t('SCHEDULED_MESSAGE.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('SCHEDULED_MESSAGE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 5rem;
}

.message-editor {
  @apply px-3;

  ::v-deep {
    .ProseMirror-menubar {
      @apply rounded-tl-[4px];
    }
  }
}
</style>
