<template>
  <IconButton
    v-tooltip.left="$t('CONVERSATION.SWITCH_VIEW_LAYOUT')"
    color-scheme="secondary"
    icon="arrow-right-import"
    @click="toggle"
  />
</template>

<script>
import IconButton from '../../../../components/ui/IconButton.vue';

export default {
  components: {
    IconButton,
  },
  props: {
    isOnExpandedLayout: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style lang="scss" soped>
.layout-switch__container {
  &.expanded .icon {
    transform: rotate(180deg);
  }
}
</style>
