import KanbanView from './components/KanbanView.vue';
import { frontendURL } from '../../../helper/URLHelper';
import SettingsWrapper from '../settings/Wrapper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/kanban'),
    component: SettingsWrapper,
    props: {
      headerTitle: 'Kanban',
      icon: 'alert',
      showNewButton: false,
      showSidemenuIcon: false,
    },
    children: [
      {
        path: '',
        name: 'kanban_index',
        component: KanbanView,
        roles: ['administrator', 'agent'],
      },
    ],
  },
];
