<template>
  <div class="flex items-center flex-col">
    <div v-if="isLoading" class="items-center flex text-base justify-center">
      <spinner color-scheme="primary" />
      <span>{{ $t('SCHEDULED_MESSAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>
    <div v-else class="w-full">
      <empty-state v-if="showEmptyResult" :title="emptyMessage" />
      <div v-else class="w-full">
        <scheduled-message-card
          v-for="scheduledMessage in scheduledMessages"
          :key="scheduledMessage.id"
          :scheduledMessage="scheduledMessage"
          @delete="scheduledMessage => $emit('delete', scheduledMessage)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import ScheduledMessageCard from './ScheduledMessageCard.vue';

export default {
  components: {
    EmptyState,
    Spinner,
    ScheduledMessageCard,
  },

  props: {
    scheduledMessages: {
      type: Array,
      default: () => [],
    },
    showEmptyResult: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxes() {
      return [
      ...this.$store.getters['inboxes/getTwilioInboxes'],
      ...this.$store.getters['inboxes/getWebsiteInboxes'],
      ...this.$store.getters['inboxes/getApiInboxes']
    ]
    },
    emptyMessage() {
      return this.inboxes.length
        ? this.$t('SCHEDULED_MESSAGE.404')
        : this.$t('SCHEDULED_MESSAGE.INBOXES_NOT_FOUND');
    },
  },
};
</script>
