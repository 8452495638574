<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="onClickAway"
      class="right-3 rtl:left-auto rtl:right-3 dropUser absolute z-30 rounded-3xl shadow-xl bg-white py-2 px-2 border border-slate-25 dark:border-slate-700"
      :class="{ 'block visible': show }"
      :style="{ width: large + 'px' }"
    >
      <availability-status />
      <woot-dropdown-menu>
        <woot-dropdown-item v-if="showChangeAccountOption">
          <a
            class="button small poppins text-black-900 hover:text-green-600 bg-white h-8 flex items-center justify-between"
            :class="{ 'is-active': isActive }"
            @click="$emit('toggle-accounts')"
          >
            <span class="text-black-900 hover:text-green-600">
              {{ $t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS') }}
            </span>
            <fluent-icon icon="arrow-swap" size="18" class="icon icon--font text-black-900 hover:text-green-600" />
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="globalConfig.chatwootInboxToken">
          <a
            class="button small poppins text-black-900 hover:text-green-600 bg-white h-8 flex items-center justify-between"
            :class="{ 'is-active': isActive }"
            @click="$emit('show-support-chat-window')"
          >
            <span class="text-black-900 hover:text-green-600">
              {{ $t('SIDEBAR_ITEMS.CONTACT_SUPPORT') }}
            </span>
            <fluent-icon icon="chat-help" size="18" class="icon icon--font text-black-900 hover:text-green-600" />
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            class="button small poppins text-black-900 hover:text-green-600 bg-white h-8 flex items-center justify-between"
            :class="{ 'is-active': isActive }"
            @click="handleKeyboardHelpClick"
          >
            <span class="text-black-900 hover:text-green-600">
              {{ $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
            </span>
            <fluent-icon icon="keyboard" size="18" class="icon icon--font text-black-900 hover:text-green-600" />
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <router-link
            v-slot="{ href, isActive, navigate }"
            :to="`/app/accounts/${accountId}/profile/settings`"
            custom
          >
            <a
              :href="href"
              class="button small poppins text-black-900 hover:text-green-600 bg-white h-8 flex items-center justify-between"
              :class="{ 'is-active': isActive }"
              @click="e => handleProfileSettingClick(e, navigate)"
            >
              <span class="text-black-900 hover:text-green-600">
                {{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
              </span>
              <fluent-icon icon="person" size="18" class="icon icon--font text-black-900 hover:text-green-600" />
            </a>
          </router-link>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            class="button small text-black-900 hover:text-green-600  bg-white h-8 flex items-center justify-between"
            :class="{ 'is-active': isActive }"
            @click="openAppearanceOptions"
          >
            <span class="text-black-900 hover:text-green-600">
              {{ $t('SIDEBAR_ITEMS.APPEARANCE') }}
            </span>
            <fluent-icon icon="appearance" size="18" class="icon icon--font text-black-900 hover:text-green-600" />
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="currentUser.type === 'SuperAdmin'">
          <a
            href="/super_admin"
            class="button small clear secondary bg-white h-8"
            target="_blank"
            rel="noopener nofollow noreferrer"
            @click="$emit('close')"
          >
            <fluent-icon
              icon="content-settings"
              size="14"
              class="icon icon--font text-black-900 hover:text-green-600"
            />
            <span class="text-black-900 hover:text-green-600">
              {{ $t('SIDEBAR_ITEMS.SUPER_ADMIN_CONSOLE') }}
            </span>
          </a>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <a
            class="button small poppins text-black-900 hover:text-green-600 bg-white h-8 items-center mt-1"
            :class="{ 'is-active': isActive }"
            @click="logout"
          >
          <fluent-icon icon="power" size="20" class="icon icon--font text-black-900 hover:text-green-600" />
            <span class="text-black-900 hover:text-green-600">
              {{ $t('SIDEBAR_ITEMS.LOGOUT') }}
            </span>
          </a>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Auth from '../../../api/auth';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import AvailabilityStatus from 'dashboard/components/layout/AvailabilityStatus.vue';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatus,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
    showChangeAccountOption() {
      if (this.globalConfig.createNewAccountFromDashboard) {
        return true;
      }

      const { accounts = [] } = this.currentUser;
      return accounts.length > 1;
    },
  },
  methods: {
    handleProfileSettingClick(e, navigate) {
      this.$emit('close');
      navigate(e);
    },
    handleKeyboardHelpClick() {
      this.$emit('key-shortcut-modal');
      this.$emit('close');
    },
    logout() {
      Auth.logout();
    },
    onClickAway() {
      if (this.show) this.$emit('close');
    },
    openAppearanceOptions() {
      const ninja = document.querySelector('ninja-keys');
      ninja.open({ parent: 'appearance_settings' });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.poppins {
    font-family: Poppins;
  }

  .dropUser{
    top: 1.9rem;
    right: 1rem;
  }
</style>
