<template>
  <div
    class="px-5 py-4 mb-2 bg-white border rounded-md dark:bg-slate-800 border-slate-50 dark:border-slate-900"
  >
    <div class="flex flex-row items-start justify-between">
      <div class="flex flex-col">
        <div
          class="mb-1 -mt-1 text-base font-medium text-slate-900 dark:text-slate-100"
        >
          {{ scheduledMessage.title }}
        </div>
        <div
          v-dompurify-html="formatMessage(scheduledMessage.message)"
          class="text-sm line-clamp-1 [&>p]:mb-0"
        />
      </div>
      <div class="flex flex-row space-x-4">
        <woot-button
          variant="link"
          icon="dismiss-circle"
          size="small"
          color-scheme="secondary"
          @click="$emit('delete', scheduledMessage)"
        >
          {{ $t('SCHEDULED_MESSAGE.LIST.BUTTONS.DELETE') }}
        </woot-button>
      </div>
    </div>

    <div class="flex flex-row items-center mt-5 space-x-3">
      <woot-label
        small
        :title="scheduledMessageStatus"
        :color-scheme="colorScheme"
        class="mr-3 text-xs"
      />
      <inbox-name :inbox="scheduledMessage.inbox" class="mb-1 ltr:ml-0 rtl:mr-0" />
      <div
        v-if="scheduledMessage.scheduled_at"
        class="mb-1 text-xs text-slate-700 dark:text-slate-500"
      >
        {{ messageStamp(new Date(scheduledMessage.scheduled_at), 'LLL d, h:mm a') }}
      </div>
      <woot-label
        small
        :title="scheduledMessageContactName"
        :color-scheme="colorScheme"
        class="mr-3 text-xs"
      />
    </div>
  </div>
</template>

<script>
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName.vue';
import InboxName from 'dashboard/components/widgets/InboxName.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from 'dashboard/mixins/time';

export default {
  components: {
    UserAvatarWithName,
    InboxName,
  },
  mixins: [messageFormatterMixin, timeMixin],
  props: {
    scheduledMessage: {
      type: Object,
      required: true,
    },
  },

  computed: {
    scheduledMessageStatus() {
      return this.scheduledMessage.sent === true
        ? this.$t('SCHEDULED_MESSAGE.LIST.STATUS.SENT')
        : this.$t('SCHEDULED_MESSAGE.LIST.STATUS.NOT_SENT');
    },
    scheduledMessageContactName() {
      return this.scheduledMessage.contact.name
    },
    colorScheme() {
      return this.scheduledMessage.sent === true
        ? 'secondary'
        : 'success';
    },
  },
};
</script>
