import { frontendURL } from '../../../helper/URLHelper';
import SettingsWrapper from '../settings/Wrapper';
import Index from './components/Index.vue'

export const routes =  [
    {
      path: frontendURL('accounts/:accountId/scheduled-messages'),
      component: SettingsWrapper,
      props: {
        headerTitle: 'SCHEDULED_MESSAGE.HEADER',
        icon: 'sound-source',
        showNewButton: false,
        showSidemenuIcon: false,
      },
      children: [
        {
          path: '',
          name: 'scheduled_messages_index',
          roles: ['administrator', 'agent'],
          component: Index,
        },
      ],
    },
  ];

