<template>
  <div class="columns" v-if="!options.length">
      <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
        <addKanban @on-close="hideAddPopup"></addKanban>
      </woot-modal>
      <div class="flex items-center justify-center min-h-[90vh] bg-gray-100">
    <div class="text-center">
      <p class="text-lg font-medium text-black-800 dark:text-white">
        Não existe nenhuma pipeline criada. Por favor, crie uma nova usando o botão abaixo.
      </p>
      <woot-button
        color-scheme="success"
        class-names="mt-4 px-4 py-2"
        icon="add-circle"
        @click="openAddPopup"
      >
        {{ buttonText }}
      </woot-button>
    </div>
  </div>
  </div>
  <div id="caixa" class="columns" v-else>
    <woot-button
        color-scheme="success"
        class-names="button--fixed-top"
        icon="add-circle"
        @click="openAddPopup"
      >
        {{ buttonText }}
      </woot-button>
      <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
        <addKanban @on-close="hideAddPopup"></addKanban>
      </woot-modal>
    <input 
      type="text" 
      v-model="filterText" 
      @input="filterCards"
      class="filter-input"
      style="width: 300px"
      placeholder="Filtrar por nome"
    />
    <div class="options">
      <button
        v-for="(option, index) in options"
        :key="index"
        class="text-black-900 dark:text-white"
        :class="{ selected: selectedOption === option }"
        @click="selectOption(option)"
      >
        {{ option.attribute_display_name }}
      </button>
    </div>
    <div class="board">
      <div
        class="line"
        v-for="(marcacaoKey, index) in Object.keys(
          selectedOption.attribute_values
        )"
        :key="index"
      >
        <h2
          class="tittle text-black-900 dark:text-white border-b-4 border-green-500"
        >
          {{ selectedOption.attribute_values[marcacaoKey] }}
        </h2>
        <Container
          class="smooth-dnd-container"
          group-name="column"
          @drag-start="handleDrag(marcacaoKey, $event)"
          @drop="handleDrop(marcacaoKey, $event)"
          :get-child-payload="getChildPayload"
          :drop-placeholder="{ className: 'drop' }"
        >
          <Draggable
            v-for="conversa in filteredAttributesArray[
              selectedOption.attribute_values[marcacaoKey]
            ]"
            :key="conversa.id"
          >
            <Card
              :labels="allLabels"
              :conversa="conversa"
              :thumbnail="conversa.meta.sender.thumbnail"
            >
              <p class="name text-black-900 dark:text-white">
                {{ conversa.meta.sender.name }}
              </p>
            </Card>
          </Draggable>
        </Container>
      </div>
    </div>
    <div>
      <woot-button
        color-scheme="success"
        class="button-more"
        @click="loadMoreConversations"
      >
        {{ buttonTextMais }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ChatList from '../../../../components/ChatList.vue';
import Card from './Card.vue';
import fakeApiConnect from './fakeApiConnect';
import { Container, Draggable } from 'vue-smooth-dnd';
import addKanban from './addKanban.vue';
import axios from 'axios';

export default {
  name: 'Kanban',
  components: { Card, Container, Draggable, addKanban, ChatList },
  data() {
    return {
      conversations: [],
      newLabels: fakeApiConnect.marcacoes,
      marcacoes: fakeApiConnect.marcacoes,
      draggingCard: {
        line: '',
        index: -1,
        cardData: {},
      },
      showAddPopup: false,
      options: [],
      attributesArray: {},
      filteredAttributesArray: {},
      selectedOption: '',
      newChatList: [],
      aux: {},
      currentPage: 1,
      pageSize: 25,
      totalRecords: 0,
      isLoading: false, 
      filterText: '', 
    };
  },
  computed: {
    buttonText() {
      return this.$t('Nova PipeLine');
    },
    buttonTextMais(){
      return this.$t('Carregar Mais');
    },
    ...mapGetters({
      labelsSide: 'labels/getLabelsOnSidebar',
      labels: 'labels/getLabels',
      chatLists: 'getAllConversations',
      account: 'getCurrentAccount',
      userAccounts: 'getUserAccounts',
      labelUiFlags: 'contactLabels/getUIFlags',
      allLabels: 'labels/getLabels',
      attributes: 'attributes/getAttributes',
    }),
    conversationList() {
      let conversationList = [];
      if (!this.hasAppliedFiltersOrActiveFolders) {
        const filters = this.conversationFilters;
        if (this.activeAssigneeTab === 'me') {
          conversationList = [...this.mineChatsList(filters)];
        } else if (this.activeAssigneeTab === 'unassigned') {
          conversationList = [...this.unAssignedChatsList(filters)];
        } else {
          conversationList = [...this.allChatList(filters)];
        }
      } else {
        conversationList = [...this.newChatList];
      }
      return conversationList;
    },
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.attributesArray = {};

      if (this.selectedOption.attribute_values[0] !== 'Não atribuido') {
        this.selectedOption.attribute_values.unshift('Não atribuido');
      }

      const filter = this.selectedOption.attribute_display_name
        .toLowerCase()
        .replace(/ /g, '_');

      this.selectedOption.attribute_values.forEach((valor) => {
        this.attributesArray[valor] = [];
      });
      this.attributesArray['Não atribuido'] = [];

      this.newChatList.forEach((conversa) => {
        let atribuida = false;
        this.selectedOption.attribute_values.forEach((valor) => {
          if (conversa.custom_attributes[filter] === valor) {
            this.attributesArray[valor].push(conversa);
            atribuida = true;
          }
        });
        if (!atribuida) {
          this.attributesArray['Não atribuido'].push(conversa);
        }
      });
      this.filterCards();
    },
    handleDrag(line, dragResult) {
      const { payload, isSource } = dragResult;

      if (line === 0) {
        return;
      }

      if (isSource) {
        this.draggingCard = {
          line,
          index: payload.index,
          cardData: {
            ...this.attributesArray[this.selectedOption.attribute_values[line]][
              payload.index
            ],
          },
        };
      }
    },
    handleDrop(line, dropResult) {
      const { removedIndex, addedIndex } = dropResult;

      if (line === this.draggingCard.line && removedIndex === addedIndex) {
        return;
      }

      if (removedIndex !== null) {
        this.aux =
          this.attributesArray[this.selectedOption.attribute_values[line]][
            removedIndex
          ];
        this.attributesArray[this.selectedOption.attribute_values[line]].splice(
          removedIndex,
          1
        );
        this.$forceUpdate();
      }

      if (addedIndex !== null) {
        this.aux = this.draggingCard.cardData;

        const targetColumn = this.selectedOption.attribute_values[line];
        this.aux.custom_attributes[this.selectedOption.attribute_key] =
          targetColumn;

        this.attributesArray[targetColumn].splice(addedIndex, 0, this.aux);
        this.onUpdateLabels(this.aux.custom_attributes, this.aux.id);
        this.aux = {};
        this.$forceUpdate();
        this.filterText = '';
        this.filterCards();
      }
    },
    getChildPayload(index) {
      return {
        index,
      };
    },
    getCards(filter) {
      return this.newChatList.filter((item) => item.custom_attributes[filter]);
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    async fetchConversations(page = 1) {
      const conversationFilters = {
        assigneeType: 'all',
        conversationType: undefined,
        folders: undefined,
        inboxId: undefined,
        labels: undefined,
        page,
        sortBy: 'latest',
        status: 'open',
        teamId: undefined,
      };

      this.isLoading = true;
      try {

        this.$store.dispatch('updateChatListFilters', conversationFilters);
        const response = await this.$store.dispatch('fetchAllConversations');

        if (page === 1) {
          this.newChatList = response.payload;
          this.totalRecords = response.meta.all_count;
        } else {
          this.newChatList = this.newChatList.concat(response.payload);
        }

        this.currentPage = page;
        if (this.selectedOption) {
          this.updateAttributesArray();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async loadMoreConversations() {
      if (this.isLoading || (this.currentPage - 1) * this.pageSize >= this.totalRecords) {
        return;
      }
      await this.fetchConversations(this.currentPage + 1);
    },
    updateAttributesArray() {
      if (!this.selectedOption || !this.selectedOption.attribute_display_name) {
        return;
      }

      const filter = this.selectedOption.attribute_display_name
        .toLowerCase()
        .replace(/ /g, '_');

      this.selectedOption.attribute_values.forEach((valor) => {
        this.attributesArray[valor] = [];
      });
      this.attributesArray['Não atribuido'] = [];

      this.newChatList.forEach((conversa) => {
        let atribuida = false;
        this.selectedOption.attribute_values.forEach((valor) => {
          if (conversa.custom_attributes[filter] === valor) {
            this.attributesArray[valor].push(conversa);
            atribuida = true;
          }
        });
        if (!atribuida) {
          this.attributesArray['Não atribuido'].push(conversa);
        }
      });
      this.filterCards();
      this.$forceUpdate();
    },
    async onUpdateLabels(updateAtributes, id) {
      try {
        await this.$store.dispatch('updateCustomAttributes', {
          conversationId: id,
          customAttributes: updateAtributes,
        });
      } catch (error) {
        this.showAlert(this.$t('CONTACT_PANEL.LABELS.CONTACT.ERROR'));
      }
    },
    filterCards() {
      if (this.filterText.trim() === '') {
        this.filteredAttributesArray = { ...this.attributesArray };
        return;
      }
      this.filteredAttributesArray = {};
      for (const key in this.attributesArray) {
        if (Object.hasOwnProperty.call(this.attributesArray, key)) {
          this.filteredAttributesArray[key] = this.attributesArray[key].filter(
            (card) =>
              card.meta.sender.name
                .toLowerCase()
                .includes(this.filterText.toLowerCase())
          );
        }
      }
    },
  },
  async mounted() {
    const indexGet = 0;
    await this.$store.dispatch('labels/get');
    await this.$store.dispatch('attributes/get', indexGet);
    if(this.attributes.length){    
    this.fetchConversations().then(() => {

      this.options = this.attributes.filter(
        (item) => item.attribute_model === 'conversation_attribute'
      );

      this.options = this.options.filter(
        (option) => option.attribute_display_type === 'list'
      );

      if(this.options.length){

        this.selectedOption = this.options[0];
  
        this.selectOption(this.selectedOption);
  
        const filteredArray = this.labels.filter((obj) => obj.title.startsWith('k-'));
  
        this.newLabels = filteredArray.map((label) => {
          const conversasCorrespondentes = this.newChatList.filter((conversa) =>
            conversa.labels.includes(label.title)
          );
          return {
            ...label,
            conversas: conversasCorrespondentes,
          };
        });
  
        this.newLabels.sort((a, b) => {
          if (a.conversas.length > 0 && b.conversas.length === 0) {
            return -1;
          } else if (a.conversas.length === 0 && b.conversas.length > 0) {
            return 1;
          } else {
            return 0;
          }
        });
        }
      });
   }
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

#caixa {
  font-family: 'Poppins', sans-serif;
  color: #ebecf0;
  flex-direction: column;
  font-weight: 600;
  line-height: 20px;
  width: 90vw;
  height: 100%;
  display: flex;
  overflow-x: auto;
  margin-left: 60px;
}

#caixa::-webkit-scrollbar {
  height: 10px;
}

#caixa::-webkit-scrollbar-thumb {
  background-color: #38c172;
}

body,
html {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #936424;
  font-size: 62.5%;
}

.board {
  display: flex;
  justify-content: space-between;
}

.button-more {
  @apply fixed top-6 flex flex-row text-black-900 hover:text-white bg-white;
    right: 400px;
    border-radius: 22.865px;
    height: 45.73px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.line {
  background: none;
  min-width: 23rem;
  border-radius: 0.8rem;
  margin: 1.2rem 0.8rem;
  padding: 0 0.7rem;
}

.tittle {
  padding: 0.8rem 0.5rem;
  margin-bottom: 0.6rem;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.drop {
  background: #22272b;
  border-radius: 0.4rem;
  transform: scaleY(0.85);
  transform-origin: 0% 0%;
}

.name {
  font-family: 'Poppins', sans-serif;
}

.options {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  margin-left: 25px;
  margin-top: 10px;
}

.options button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  outline: none;
}

.options button.selected {
  border-bottom: 2px solid rgb(34 197 94);
}

.smooth-dnd-container {
  min-height: 300px;
  min-width: 30px;
  max-height: 55rem;
  overflow-y: auto;
}

.blocked-column {
  cursor: not-allowed;
}

.sentinel {
  width: 100%;
  height: 20px;
}

.filter-input {
  @apply fixed;
  width: 300px;
  top: 24px;
  right: 550px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
</style>
