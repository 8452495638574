<template>
  <div class="flex-1 overflow-auto p-4 dark:bg-slate-800">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ buttonText }}
    </woot-button>
    <scheduled-message />
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-scheduled-message @on-close="hideAddPopup" />
    </woot-modal>
  </div>
</template>

<script>
import ScheduledMessage from './ScheduledMessage.vue';
import AddScheduledMessage from './AddScheduledMessage.vue';

export default {
  components: {
    ScheduledMessage,
    AddScheduledMessage,
  },
  data() {
    return { showAddPopup: false };
  },
  computed: {
    buttonText() {
      return this.$t('SCHEDULED_MESSAGE.HEADER_BTN_TXT');
    },
  },
  mounted() {
    this.$store.dispatch('scheduledMessages/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
};
</script>
