<template>
  <div class="flex-1 overflow-auto">
    <scheduled-messages-table
      :scheduled-messages="scheduledMessages"
      :show-empty-result="showEmptyResult"
      :is-loading="uiFlags.isFetching"
      @delete="openDeletePopup"
    />
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('SCHEDULED_MESSAGE.DELETE.CONFIRM.TITLE')"
      :message="$t('SCHEDULED_MESSAGE.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('SCHEDULED_MESSAGE.DELETE.CONFIRM.YES')"
      :reject-text="$t('SCHEDULED_MESSAGE.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import ScheduledMessagesTable from './ScheduledMessagesTable.vue';

export default {
  components: {
    ScheduledMessagesTable,
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedScheduledMessage: {},
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'scheduledMessages/getUIFlags',
      labelList: 'labels/getLabels',
    }),
    scheduledMessages() {
      return this.$store.getters['scheduledMessages/getAllScheduledMessages'];
    },
    showEmptyResult() {
      const hasEmptyResults =
        !this.uiFlags.isFetching && this.scheduledMessages.length === 0;
      return hasEmptyResults;
    },
  },
  methods: {
    openDeletePopup(scheduledMessage) {
      this.showDeleteConfirmationPopup = true;
      this.selectedScheduledMessage = scheduledMessage;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      const { id } = this.selectedScheduledMessage;
      this.deleteScheduledMessage(id);
    }, 
    async deleteScheduledMessage(id) {
      try {
        await this.$store.dispatch('scheduledMessages/delete', id);
        this.showAlert(this.$t('SCHEDULED_MESSAGE.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('SCHEDULED_MESSAGE.DELETE.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.button-wrapper {
  @apply flex justify-end pb-2.5;
}
</style>
