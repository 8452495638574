<template>
  <div class="card bg-white dark:bg-slate-800 shadow-md" @click="openChat()">
    <div class="content-wrapper">
      <thumbnail
        :src="thumbnail"
        :badge="inboxBadge"
        class="rounded-logo"
        :username="conversa.meta.sender.name"
        :status="conversa.meta.sender.availability_status"
        size="40px"
      />
      <div class="text-section">
        <div class="inline-flex items-center">
          <fluent-icon
            class="mr-0.5 rtl:ml-0.5 rtl:mr-0 gray-text"
            :icon="computedInboxClass"
            size="15"
          />
          <p class="sub-text gray-text">{{ inbox.name }}</p>
          <span class="ml-5 font-normal leading-4 text-black-600 text-xxs">
          <time-ago
            :last-activity-timestamp="conversa.timestamp"
            :created-at-timestamp="conversa.created_at"
          />
        </span>
        </div>
        <slot class="main-text text-black-900 dark:text-white">User</slot>
        <message-preview
        v-if="conversa.messages[0]"
        :message="conversa.messages[0]"
        class="conversation--message my-0 leading-6 h-6 max-w-[96%] w-[16.875rem] text-sm text-slate-700 dark:text-slate-200"
        />
        <div class="sub-text sub-custom" v-for="label in conversa.labels" :key="label" :style="getLabelStyle(label)">
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Thumbnail from '../../../../components/widgets/Thumbnail.vue';
import { getInboxClassByType } from 'dashboard/helper/inbox';
import TimeAgo from 'dashboard/components/ui/TimeAgo.vue';
import MessagePreview from './MessagePreview.vue';

export default {
  name: 'Card',
  components: {
    Thumbnail,
    TimeAgo,
    MessagePreview
  },
  data(){
    return{
      inbox: {}
    }
  },
  computed: {
    computedInboxClass() {
      const { phone_number: phoneNumber, channel_type: type } = this.inbox;
      var classByType = getInboxClassByType(type, phoneNumber);
      if(classByType == 'cloud'){
        classByType = 'whatsapp'
      }
      return classByType;
    },
  },
  methods: {
    openChat(){
      const baseUrl = `${window.location.origin}/app/accounts`;
      const url = `${baseUrl}/${this.conversa.account_id}/conversations/${this.conversa.id}`;
      window.open(url, '_blank');
    },
    getInbox(inboxId){
      return this.$store.getters['inboxes/getInbox'](inboxId);
    },
    getLabelStyle(label) {
      const labelObj = this.labels.find((item) => item.title === label);
      const bgColor = labelObj ? labelObj.color : "#011A00";

      return {
        backgroundColor: bgColor,
        color: this.getTextColor(bgColor),
      };
    },
    getTextColor(bgColor) {
      const rgb = this.hexToRgb(bgColor);
      const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
      return brightness > 128 ? "black" : "white";
    },
    hexToRgb(hex) {
      hex = hex.replace(/^#/, '');

      let bigint = parseInt(hex, 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;

      return { r, g, b };
    },
  },
  props: {
    conversa: {
      type: Object,
      required: true
    },
    labels:{
      type: Object,
      required: true
    },
    thumbnail: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
   mounted(){
     this.inbox = this.getInbox(this.conversa.inbox_id)
  }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.fixeds{
  display: inline-flex;
  align-items: center;
}

.card {
  display: flex;
  min-height: 100px;
  border-radius: 4px;
  border: none;
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
  outline: none;
}

.content-wrapper {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.rounded-logo {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.text-section {
  flex: 1;
}

.main-text {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.sub-text {
  display: inline-block;
  font-size: 11px;
  margin: 5px 0;
}

.sub-custom{
  font-family: "Poppins", sans-serif;
  padding-right: 3px;
  padding-left: 3px;
  border-radius: 4px;
  margin-left: 4px;
}

.gray-text {
  color: #888;
}

.label-color-dot {
  display: inline-block;
  @apply w-2 h-2 rounded-sm shadow-sm;
}

.marginNegative{
  margin-left: -13px;
}
</style>
